module.exports =
    [
        { text: "Seçin", value: "" },
        {text: "Ferdi Sporcu", value: "individual"},
        { text: "Antrenör", value: "trainerState" },
        { text: "Hakem", value: "sportRefereeState" },
        { text: "İl Temsilcisi", value: "provincialRepresentative" },
        { text: "Kurul Üyesi", value: "assemblyMember" },
        { text: "Personel", value: "personel" },
        { text: "Sporcu", value: "sportState" },
        { text: "Yönetici", value: "manager" },
    ];
